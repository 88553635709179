export enum DashboardFieldType {
  registrationDate = 'registrationDate',
  completionDate = 'completionDate',
  ecoNumber = 'ecoNumber',
  loanStatus = 'loanStatus',
  action = 'action',
  loanApplicationNumber = 'loanApplicationNumber',
  clientName = 'clientName',
  clientNumber = 'clientNumber',
  segment = 'segment',
  emStatus = 'emStatus',
  amount = 'amount',
  currency = 'currency',
  greenMeasures = 'greenMeasures',
  categories = 'categories',
  ecoLoanType = 'ecoLoanType',
  responsibleUser = 'responsibleUser',
}

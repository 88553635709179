export enum MeasureListFieldType {
  category = 'category',
  key = 'key',
  name = 'name',
  approach = 'approach',
  eligibilityCriteria = 'eligibilityCriteria',
  impactCalculated = 'impactCalculated',
  form = 'form',
  action = 'action',
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { CompletionDataResponse } from 'src/app/private/interfaces';

export const CreateLoanCompletionActions = createActionGroup({
  source: '[Create Loan Completion]',
  events: {
    'Initialize Page': props<{ loanId: string }>(),

    'Get Data': props<{ loanId: string }>(),
    'Get Data Success': httpSuccessProps<CompletionDataResponse>(),
    'Get Data Error': httpErrorProps(),

    'Reset Data': emptyProps(),
  },
});

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import {
  GeneralInfoLookups,
  MeasureSectionLookups,
  LoanApiState,
} from 'src/app/private/interfaces';

export const CreateLoanActions = createActionGroup({
  source: '[Create Loan]',
  events: {
    'Initialize Page': props<{ loanId: string }>(),
    'Reload Page': props<{ loanId: string }>(),
    'Reset Page': emptyProps(),

    'Get Measure Section Lookups': emptyProps(),
    'Get Measure Section Lookups Success':
      httpSuccessProps<MeasureSectionLookups>(),
    'Get Measure Section Lookups Error': httpErrorProps(),

    'Get Loan States': props<{ loanId: string }>(),
    'Get Loan States Success': httpSuccessProps<LoanApiState>(),
    'Get Loan States Error': httpErrorProps(),

    'Plausibility Check': emptyProps(),
    'Plausibility Check Success': httpSuccessProps<string>(),
    'Plausibility Check Error': httpErrorProps(),

    Complete: emptyProps(),
    'Complete Success': httpSuccessProps<string>(),
    'Complete Error': httpErrorProps(),

    'Change Tab Index': props<{ tabIndex: number }>(),

    'Redirect To Dashboard': emptyProps(),
  },
});

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  get cookies(): Map<string, string> {
    return document.cookie
      .split(';')
      .map((str) => str.trim())
      .map((str) => str.split('='))
      .reduce(
        (map: Map<string, string>, [key, value]) => map.set(key, value),
        new Map<string, string>()
      );
  }

  get(key: string) {
    return this.cookies.get(key);
  }
}

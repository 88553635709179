import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { CurrentUser } from 'src/app/shared/interfaces';

/**
 * @ignore
 */
export const AppActions = createActionGroup({
  source: '[App]',
  events: {
    'Application init': emptyProps(),

    'Get Current User': emptyProps(),
    'Get Current User Success':
      httpSuccessProps<CurrentUser>(),
    'Get Current User Error': httpErrorProps(),

    'Set Tenant Id': props<{ tenantId: string | null }>(),
  },
});

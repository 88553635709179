import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { Select } from 'src/app/shared/interfaces';
import {
  CalculationModuleData,
  CalculationModuleDataSection,
  CalculationModuleLookups,
} from 'src/app/private/interfaces';

export const CalculationModuleActions = createActionGroup({
  source: '[Calculation Module]',
  events: {
    'Initialize Page': props<{ id: string }>(),
    'Reload Page': props<{ id: string }>(),

    'Get Lookups': emptyProps(),
    'Get Lookups Success': httpSuccessProps<CalculationModuleLookups>(),
    'Get Lookups Error': httpErrorProps(),

    'Get Data': props<{ id: string }>(),
    'Get Data Success': httpSuccessProps<CalculationModuleData>(),
    'Get Data Error': httpErrorProps(),

    Create: props<{ name: string }>(),
    'Create Success': httpSuccessProps<string>(),
    'Create Error': httpErrorProps(),

    Update: props<{ data: { name: string; id: string } }>(),
    'Update Success': httpSuccessProps<string>(),
    'Update Error': httpErrorProps(),

    'Update Section': props<{ data: CalculationModuleDataSection }>(),
    'Update Section Success': httpSuccessProps<string>(),
    'Update Section Error': httpErrorProps(),

    'Publish Data': props<{ id: string }>(),
    'Publish Data Success': httpSuccessProps<string>(),
    'Publish Data Error': httpErrorProps(),

    'Check Existence Name': props<{ name: string }>(),
    'Check Existence Name Success': httpSuccessProps<boolean>(),
    'Check Existence Name Error': httpErrorProps(),

    'Get Eligible Dropdown List': props<{ dropdownType: string }>(),
    'Get Eligible Dropdown List Success': httpSuccessProps<{options: Select[]}>(),
    'Get Eligible Dropdown List Error': httpErrorProps(),

    Reset: emptyProps(),
  },
});

import { createActionGroup, props } from '@ngrx/store';
import { AccountInfo } from '@azure/msal-browser';

/**
 * @ignore
 */
export const AuthorizationActions = createActionGroup({
  source: '[Authorization]',
  events: {
    'Set Account Info': props<{ accountInfo: AccountInfo | null }>(),
  },
});

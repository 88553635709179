import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { BaseTable } from 'src/app/shared/interfaces';
import { ListModel } from 'src/app/shared/models';
import { MeasureListItem } from 'src/app/private/interfaces';

export const MeasuresListActions = createActionGroup({
  source: '[Measures List]',
  events: {
    'Initialize Page': emptyProps(),

    'Load List': emptyProps(),
    'Load List Success':
      httpSuccessProps<ListModel<MeasureListItem>>(),
    'Load List Error': httpErrorProps(),

    'Remove Measure': props<{ id: string }>(),
    'Remove Measure Success': httpSuccessProps<boolean>(),
    'Remove Measure Error': httpErrorProps(),

    'Change Status': props<{ id: string, enable: boolean }>(),
    'Change Status Success': httpSuccessProps<boolean>(),
    'Change Status Error': httpErrorProps(),

    'Apply Pagination': props<BaseTable>(),
  },
});

import { inject, Injectable } from '@angular/core';
import { CookieService } from '../cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly cookieService = inject(CookieService);

  getBearerAuth(): string {
    return `Bearer ${this.getToken()}`;
  }

  getToken() {
    const token = this.cookieService.get('EcoModuleApiToken');
    return token;
  }
}

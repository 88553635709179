export function IsEmptyObject(data: Record<string, any>) {
  const noEmptyData = {} as Record<string, any>;
  Object.keys(data)
    .filter(
      (i) =>
        (data[i] || data[i] === false) &&
        (!(data[i] instanceof Array) || data[i].length)
    )
    .forEach((key) => (noEmptyData[key] = data[key]));

  return !Object.keys(noEmptyData).length;
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { BASE_URL } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class BaseHttp {
  constructor(
    @Inject(BASE_URL) protected readonly baseUrl: string,
    protected readonly http: HttpClient,
    protected readonly auth: AuthService
  ) {}

  get headers() {
    return new HttpHeaders()
      .append('Authorization', this.auth.getBearerAuth())
      .append('Pragma', 'no-cache');
  }
}

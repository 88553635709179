import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { BaseTable } from 'src/app/shared/interfaces';
import { ListModel } from 'src/app/shared/models';
import { CalculationModuleListItem } from 'src/app/private/interfaces';

export const CalculationModuleListActions = createActionGroup({
  source: '[Calculation Module List]',
  events: {
    'Initialize Page': emptyProps(),

    'Load List': emptyProps(),
    'Load List Success':
      httpSuccessProps<ListModel<CalculationModuleListItem>>(),
    'Load List Error': httpErrorProps(),

    'Remove Module': props<{ id: string }>(),
    'Remove Module Success': httpSuccessProps<boolean>(),
    'Remove Module Error': httpErrorProps(),

    'Apply Pagination': props<BaseTable>(),
  },
});

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { BaseTable } from 'src/app/shared/interfaces';
import { ListModel } from 'src/app/shared/models';
import { DropdownListItem } from 'src/app/private/interfaces';

export const DropdownListActions = createActionGroup({
  source: '[Dropdown List]',
  events: {
    'Initialize Page': emptyProps(),

    'Load List': emptyProps(),
    'Load List Success':
      httpSuccessProps<ListModel<DropdownListItem>>(),
    'Load List Error': httpErrorProps(),

    'Remove Dropdown': props<{ id: string }>(),
    'Remove Dropdown Success': httpSuccessProps<boolean>(),
    'Remove Dropdown Error': httpErrorProps(),

    'Apply Pagination': props<BaseTable>(),
  },
});

import { createActionGroup, emptyProps } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { ReportsData } from 'src/app/private/interfaces';

export const ReportsActions = createActionGroup({
  source: '[Reports]',
  events: {
    'Initialize Page': emptyProps(),
    'Get Data': emptyProps(),
    'Get Data Success': httpSuccessProps<ReportsData>(),
    'Get Data Error': httpErrorProps(),
  },
});

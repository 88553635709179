import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BaseTable } from 'src/app/shared/interfaces';
import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { LoanFilter } from 'src/app/private/interfaces/loan-filter.interface';
import { LoanListItem } from 'src/app/private/interfaces/loan-list-item.interface';
import { ListModel } from 'src/app/shared/models/list.model';
import {
  FiltersLookups,
  LoanSearch,
  LoanSort,
  MeasureTypesLookups,
} from 'src/app/private/interfaces';

export const HomePageActions = createActionGroup({
  source: '[Home Page]',
  events: {
    'Load List': emptyProps(),
    'Load List Success': httpSuccessProps<ListModel<LoanListItem>>(),
    'Load List Error': httpErrorProps(),

    'Initialize Page': props<{ tenantId: string }>(),

    'Export Actual List': emptyProps(),
    'Export Actual List Success': props<{ data: Blob }>(),
    'Export Actual List Error': httpErrorProps(),

    'Get Filters Lookups': emptyProps(),
    'Get Filters Lookups Success': httpSuccessProps<FiltersLookups>(),
    'Get Filters Lookups Error': httpErrorProps(),

    'Get Measure Types Lookups': emptyProps(),
    'Get Measure Types Lookups Success':
      httpSuccessProps<MeasureTypesLookups>(),
    'Get Measure Types Lookups Error': httpErrorProps(),

    'Accept Filter': props<{ filterData: LoanFilter }>(),
    'Reset Filter': emptyProps(),
    'Remove Filter Item': props<{ changeProperty: Partial<LoanFilter> }>(),
    'Start Search': props<{ searchData: LoanSearch }>(),
    'Start Sorting': props<{ sortData: LoanSort }>(),
    'Apply Pagination': props<BaseTable>(),

    'Remove Loan': props<{ id: string }>(),
    'Remove Loan Success': httpSuccessProps<boolean>(),
    'Remove Loan Error': httpErrorProps(),
  },
});

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import {
  MeasureData,
  MeasureLookups,
  MeasureUniqueFields,
} from 'src/app/private/interfaces';

export const CreateMeasuresActions = createActionGroup({
  source: '[Create Measures]',
  events: {
    'Initialize Page': props<{ measureId: string }>(),

    'Get Lookups': emptyProps(),
    'Get Lookups Success': httpSuccessProps<MeasureLookups>(),
    'Get Lookups Error': httpErrorProps(),

    'Get Data': props<{ measureId: string }>(),
    'Get Data Success': httpSuccessProps<MeasureData>(),
    'Get Data Error': httpErrorProps(),

    'Create': props<{ data: MeasureData }>(),
    'Create Success': httpSuccessProps<string>(),
    'Create Error': httpErrorProps(),

    'Update': props<{ data: MeasureData }>(),
    'Update Success': httpSuccessProps<string>(),
    'Update Error': httpErrorProps(),

    'Check Existence Key': props<{ data: MeasureUniqueFields }>(),
    'Check Existence Key Success': httpSuccessProps<boolean>(),
    'Check Existence Key Error': httpErrorProps(),

    'Check Existence Name': props<{ data: MeasureUniqueFields }>(),
    'Check Existence Name Success': httpSuccessProps<boolean>(),
    'Check Existence Name Error': httpErrorProps(),

    'Reset Existence Data': emptyProps(),
    'Reset Page': emptyProps(),
    'Edit Page': emptyProps(),
  },
});

export function EqualObjects<T extends Record<string, any>>(
  obj1: T,
  obj2: T
): boolean {
  if (areBothNullOrUndefined(obj1, obj2)) {
    return true;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    return areArraysEqual(obj1, obj2);
  }

  return compareObjectsProperties(obj1, obj2);
}

function areBothNullOrUndefined<T extends Record<string, any>>(
  obj1: T,
  obj2: T
): boolean {
  if (
    (obj1 === null && obj2 === null) ||
    (obj1 === undefined && obj2 === undefined)
  ) {
    return true;
  }
  return false;
}

function areArraysEqual(obj1: any[], obj2: any[]) {
  if (obj1.length !== obj2.length) {
    return false;
  }

  for (let i = 0; i < obj1.length; i++) {
    if (!EqualObjects(obj1[i], obj2[i])) {
      return false;
    }
  }
  return true;
}

function isPropStringType(props: any[]): any[] {
  return props.map((prop) => (typeof prop === 'string' ? prop.trim() : prop));
}

function compareObjectsProperties(obj1: any, obj2: any): boolean {
  const props1 = Object.getOwnPropertyNames(obj1);
  const props2 = Object.getOwnPropertyNames(obj2);

  if (props1.length !== props2.length) {
    return false;
  }

  for (let value of props1) {
    const propName = value;
    let val1 = obj1[propName] ?? '';
    let val2 = obj2[propName] ?? '';

    [val1, val2] = isPropStringType([val1, val2]);

    if (!areObjectValuesEqual(val1, val2)) {
      return false;
    }
  }
  return true;
}

function areObjectValuesEqual(val1: any, val2: any) {
  if (typeof val1 === 'object' && typeof val2 === 'object') {
    if (val1 instanceof Date && val2 instanceof Date) {
      if (val1.toDateString() !== val2.toDateString()) {
        return false;
      }
    } else if (!EqualObjects(val1, val2)) {
      return false;
    }
  } else if (val1 !== val2) {
    return false;
  }

  return true;
}

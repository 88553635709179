import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { AttachmentsRequest } from 'src/app/shared/interfaces';
import {
  Measure,
  MeasuresApiState,
  MeasuresGetData,
} from 'src/app/private/interfaces';

export const MeasuresActions = createActionGroup({
  source: '[Loan Measures]',
  events: {
    'Initialize Page': props<{ loanId: string }>(),

    'Get State': emptyProps(),
    'Get State Success': httpSuccessProps<MeasuresApiState>(),
    'Get State Error': httpErrorProps(),

    'Get Data': emptyProps(),
    'Get Data Success': httpSuccessProps<MeasuresGetData>(),
    'Get Data Error': httpErrorProps(),

    'Check Data': props<{
      measures: Measure<AttachmentsRequest>[];
      loanId: string;
    }>(),
    'Check Data Success': props<{
      measures: Measure<AttachmentsRequest>[];
      sentToEmu: boolean;
    }>(),
    'Check Data Error': httpErrorProps(),

    Update: props<{
      measures: Measure<AttachmentsRequest>[];
      redirect: boolean;
    }>(),
    'Update Success': httpSuccessProps<string>(),
    'Update Error': httpErrorProps(),

    Reset: emptyProps(),
    Edit: emptyProps(),
  },
});

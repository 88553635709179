export enum FieldType {
  Text = 1,
  Number = 2,
  Checkbox = 3,
  RadioButton = 4,
  Dropdown = 5,
  MultiselectDropdown = 6,
  DatePicker = 7,
  FilePicker = 8,
  YearPicker = 9,
  MonthPicker = 10,
  WholeNumber = 11,
  MathematicalOperations = 50,
  EligibilityCrosscheck = 60,
}

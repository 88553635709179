import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import { CreateDropdown, DropdownResponseData } from 'src/app/private/interfaces';

export const CreateDropdownActions = createActionGroup({
  source: '[Create Dropdown]',
  events: {
    'Initialize Page': props<{ dropdownId: string }>(),

    'Get Data': props<{ dropdownId: string }>(),
    'Get Data Success': httpSuccessProps<DropdownResponseData>(),
    'Get Data Error': httpErrorProps(),

    'Create': props<{data: CreateDropdown}>(),
    'Create Success': httpSuccessProps<string>(),
    'Create Error': httpErrorProps(),

    'Update': props<{data: CreateDropdown}>(),
    'Update Success': httpSuccessProps<string>(),
    'Update Error': httpErrorProps(),

    'Get Existence Field Name': props<{ name: string }>(),
    'Get Existence Field Name Success': httpSuccessProps<boolean>(),
    'Get Existence Field Name Error': httpErrorProps(),

    'Edit Page': emptyProps(),
    'Cancel Page': emptyProps(),
    'Reset Page': emptyProps(),
  },
});

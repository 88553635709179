import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import {
  CalculationDataRequest,
  CalculationDataResponse,
  CalculationApiState,
} from 'src/app/private/interfaces';

export const CalculationActions = createActionGroup({
  source: '[Calculation]',
  events: {
    'Initialize Page': props<{ loanId: string }>(),

    'Get State': emptyProps(),
    'Get State Success': httpSuccessProps<CalculationApiState>(),
    'Get State Error': httpErrorProps(),

    'Get Data': emptyProps(),
    'Get Data Success': httpSuccessProps<CalculationDataResponse[]>(),
    'Get Data Error': httpErrorProps(),

    'Start Calculation': emptyProps(),
    'Edit Data': emptyProps(),
    'Reset Data': emptyProps(),
    'Invalid Data': emptyProps(),

    'Save Data': props<{ data: CalculationDataRequest[] }>(),
    'Save Data Success': httpSuccessProps<string>(),
    'Save Data Error': httpErrorProps(),

    'Process Data': props<CalculationDataRequest>(),
    'Process Data Success': httpSuccessProps<CalculationDataResponse>(),
    'Process Data Error': httpErrorProps(),
  },
});

import { createActionGroup, props } from '@ngrx/store';

import { Attachments } from 'src/app/shared/interfaces';
import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';

export const AttachmentsActions = createActionGroup({
  source: '[Attachments]',
  events: {
    'Upload': props<{data: FormData}>(),
    'Upload Success': httpSuccessProps<{documents: Attachments[]}>(),
    'Upload Error': httpErrorProps(),

    'Download': props<{id: string, fileName: string}>(),
    'Download Success': props<{ data: Blob, fileName: string }>(),
    'Download Error': httpErrorProps(),
  }
});

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { httpErrorProps, httpSuccessProps } from 'src/app/shared/functions';
import {
  GeneralInfoApiState,
  GeneralInfoLookups,
  GeneralInformation,
} from 'src/app/private/interfaces';
import { GeneralInfoImport } from 'src/app/private/interfaces/general-info-import.interface';

export const GenInfoActions = createActionGroup({
  source: '[Gen Info]',
  events: {
    'Initialize Register Page': emptyProps(),
    'Initialize Detail Page': props<{ loanId: string }>(),

    'Get Lookups': emptyProps(),
    'Get Lookups Success': httpSuccessProps<GeneralInfoLookups>(),
    'Get Lookups Error': httpErrorProps(),

    'Get State': emptyProps(),
    'Get State Success': httpSuccessProps<GeneralInfoApiState>(),
    'Get State Error': httpErrorProps(),

    'Check Data': props<{
      data: GeneralInformation;
      isImported: boolean;
      sync: boolean;
    }>(),
    'Check Data Success': props<{ sentToEmu: boolean }>(),
    'Check Data Error': httpErrorProps(),

    Create: props<{
      data: GeneralInformation;
      redirect: boolean;
    }>(),
    'Create Success': httpSuccessProps<string>(),
    'Create Error': httpErrorProps(),

    'Create Imported Data': props<{
      data: GeneralInformation;
      redirect: boolean;
    }>(),
    'Create Imported Data Success': httpSuccessProps<string>(),
    'Create Imported Data Error': httpErrorProps(),

    Update: props<{
      data: GeneralInformation;
      redirect: boolean;
    }>(),
    'Update Success': httpSuccessProps<string>(),
    'Update Error': httpErrorProps(),

    'Update Imported Data': props<{
      data: GeneralInformation;
      sync: boolean;
      redirect: boolean;
    }>(),
    'Update Imported Data Success': httpSuccessProps<string>(),
    'Update Imported Data Error': httpErrorProps(),

    'Get Data': emptyProps(),
    'Get Data Success': httpSuccessProps<GeneralInformation>(),
    'Get Data Error': httpErrorProps(),

    Import: props<{ loanApplicationNumber: string }>(),
    'Import Success': httpSuccessProps<GeneralInfoImport>(),
    'Import Error': httpErrorProps(),

    'Get Existence Loan': props<{ loanApplicationNumber: string }>(),
    'Get Existence Loan Success': httpSuccessProps<boolean>(),
    'Get Existence Loan Error': httpErrorProps(),

    'Start Get Existence Loan': emptyProps(),
    'Reset Existence Loan': emptyProps(),
    'Reset Is Imported Loan': emptyProps(),

    Edit: emptyProps(),
    Cancel: emptyProps(),
  },
});
